<template>
    <div class="bidding">
        <div class="main">
            <el-breadcrumb separator-class="el-icon-arrow-right" class="mb-4">
                <el-breadcrumb-item :to="{ path: '/zixun' }">资讯中心</el-breadcrumb-item>
                <el-breadcrumb-item v-if="title == 'public'" :to="{ path: '/NoticeList?'+ 'modes=' + 'Procure'}">实时公告</el-breadcrumb-item>
                <el-breadcrumb-item v-if="title == 'public'">公告详情</el-breadcrumb-item>
            </el-breadcrumb>

            <el-card class="box-card">
                <div class="main-title">{{ bidnames }}
                    <router-link  style="margin-left: 65%;font-size: 18px;font-weight: bold"
                            :to="{ path: '/product', query: {ptargetid:selectData.ptargetid,activeName:''}}">
                        <el-button style="font-size: 18px;font-weight: bold" v-if="activeName === '0'">竞价结果：已拍下</el-button>
                        <el-button style="font-size: 18px;font-weight: bold" v-if="activeName === '1'">竞价结果：已流拍</el-button>
                        <el-button style="font-size: 18px;font-weight: bold" v-if="activeName === '2'">竞价结果：已关闭</el-button>
                        <el-button style="font-size: 18px;font-weight: bold" v-if="activeName === '3'">竞价结果：进行中（或等待竞拍结果）</el-button>
                        <el-button style="font-size: 18px;font-weight: bold" v-if="activeName === '4'" >竞价状态：准备中</el-button>
                    </router-link>
                </div>
                <div class="main-body">
                    <div class="main-center">
                        <el-collapse v-model="activeNames">
                            <el-collapse-item name="1">
                                <template slot="title">
                                    <div class="collapse-title p-2" style="font-weight: bold;font-size: 18px">竞买公告</div>
                                </template>
                                <el-card>
                                    <div class="w-100 h-100 item-8-details">
                                        <div class="item-8-title">中煤销售太原有限公司竞价事项说明</div>
                                        <div class="item-8-title">({{bidnames}})</div>
                                        <div class="item-8-title" style="font-weight: bolder">承诺函</div>
                                        <div style="font-weight: bolder">一、综合说明</div>
                                        <div style="font-weight: bolder">当前场次</div>
                                        <div>1、竞价物资：{{productInformationTemp.pproductname}}</div>
                                        <div>2、竞价单位：</div>
                                        <div v-for="(item,index) in LastOffer" :key="index">
                                            <p style="text-indent:1.6em;">{{item.enterprisename}}</p>
                                        </div>
                                        <!--                                <div>3、竞价方式：{{this.biddingRules[0].data01}}</div>-->
                                        <div>3、竞价期间：{{detailsStartTime}}至{{detailsEndTime}}</div>
                                        <!--                                <div>5、供货期限：暂定{{transactionAttributes[0].trtime}}，在此期间内，具体发货时间以实际销售安排为准。</div>-->
                                        <div>4、竞价商品基本信息:
                                            <div>
                                                <el-table :data="transactionNoticeTemps" class="w-100" border
                                                          :header-cell-style="productInformationStyle">
                                                    <el-table-column label="竞价物资" align="center" prop="pproductname"></el-table-column>
                                                    <el-table-column label="产地" align="center" prop="delivery"></el-table-column>
                                                    <el-table-column label="交割地" align="center" prop="delivery"></el-table-column>
                                                    <el-table-column label="提货方式" align="center" prop="delivertype"></el-table-column>
                                                    <el-table-column label="数量（吨）" align="center" prop="quantity"></el-table-column>
                                                    <el-table-column label="付款方式" align="center" prop="payment"></el-table-column>
                                                    <el-table-column label="竞拍基价（元/吨）" align="center" prop="price"></el-table-column>
                                                </el-table>
                                            </div>
                                        </div>
                                        <div>7、交易产品参考质量指标:
                                            <el-table :data="productInformations" class="w-100" border
                                                      :header-cell-style="productInformationStyle">
                                                <el-table-column label="更多信息" align="center">
                                                    <template>
                                                        <span>{{pdescription}}</span>
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                        </div>

                                        <div>
                                            <div style="font-weight: bolder">二、竞价内容及说明：</div>
                                            <div>1、实行履约诚意金制度</div>
                                            <div>(1)为维护交易双方的合法权益，参加竞价客户(以下简称“买方”)须缴纳诚意金5万元，需公户付款，不接受个人缴款和现金入账。</div>
                                            <div>(2)买方需在开始竞拍前1小时，一次性缴纳诚意金5万元，诚意金不能冲抵煤款，逾期未足额缴纳诚意金的企业将无法参与竞价交易。诚意金账户信息：</div>
                                            <div>开户行：中国建设银行太原高新技术园区支行</div>
                                            <div> 账  号：1405 0182 6408 0951 8899</div>
                                            <div>开户单位：中煤销售太原有限公司</div>
                                            <div>(3) 竞价交易成功后，买方在两个工作日内签订煤炭买卖合同，全额支付货款。如果买方在规定时间内未签订合同，支付货款，将视为违约，中煤销售太原有限公司将罚没履约诚意金。</div>
                                            <div>(4)参与竞价的用户均须严格遵守本交易平台的交易办法、交易流程和交易制度，并严格按照交易规则办理诚意金的提交手续和退还手续。本交易平台对参与竞价用户提交的诚意金不支付任何利息。</div>
                                            <div>2、竞卖加价：每次报价的最小竞卖加价为1元/吨，基价可以作为挂牌价格。</div>
                                            <div>3、交货地点及方式：在王家岭矿场地买方自提。煤炭运杂费用、运输过程中的安全问题及意外事件由买方自己承担。</div>
                                            <div> 4、结算方式：全额现汇预付货款。</div>
                                            <div> 5、质量和数量的验收标准及方法：质量以王家岭矿化验结果为准，数量以王家岭矿过衡计量数量为结算依据。</div>
                                            <div style="font-weight: bolder">三、注意事项：</div>
                                            <div> 1、报名方式：进入首页，查看到“公开销售”中竞卖信息，点击竞卖信息，进入登录页面，输入账号和密码登录系统（默认密码123456，登录系统后请及时修改密码，妥善保管自己的账号和密码），没有账号用户请进行注册。选择竞卖--等待竞卖--报名。</div>
                                            <div>2、在线竞价方式：凭注册的用户名及密码登录系统选择竞卖--竞卖中--参与竞价。</div>
                                            <div> 3、竞价结果查询：凭注册用户的微信登录系统选择“我的”--竞卖订单--查看竞价结果。</div>
                                            <div>4、通过本交易平台竞价程序取得成交资格的交易商违约的，将不予退还已经提交的诚意金，竞价标的物将由本交易平台另行处理。请诸位理性出价。</div>
                                            <div> 5、如经本交易平台利用技术手段掌握个别交易商在竞价过程中存在“参与方恶意串通、人为控制交易价格”的行为，则本交易平台有权立即中止竞价或取消竞价。</div>
                                            <div>6、如因黑客攻击、系统故障、服务器故障等原因，导致竞价中断或无法正常进行的，则该场次的竞价程序无效，且本交易平台有权对该场次竞价全权进行处理，任何交易商均无权干涉。</div>
                                            <div>7、请妥善保管自己的账号和密码，严禁对外泄露，该帐号下发生的所有竞拍行为平台视为有效，客户自行承担全部责任。</div>
                                            <div style="font-weight: bolder"> 四、其他：</div>
                                            <div>1、该竞价解释权归中煤销售太原有限公司所有。</div>
                                            <div>  2、联系方式 <div>
                                                联系人：杨科长                                          联系方式：15934048233
                                            </div>
                                                <div style="text-align: right">中煤销售太原有限公司</div>
                                                <div style="text-align: right">{{detailsCreatTime}}</div>
                                            </div>
                                            <el-button @click="exportWordNotice" type="primary" style="float: right;margin-bottom: 5px;">导出word</el-button>
                                        </div>
                                    </div>
                                </el-card>
                            </el-collapse-item>
                        </el-collapse>
                    </div>
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
    import JSZipUtils from "jszip-utils"
    import docxtemplater from "docxtemplater"
    import {saveAs} from 'file-saver'
    import JSZip from 'jszip'
    export default {
        name: "ProductXQ",
        data() {
            return {
              // iframeState: true,
              // url: '',
              // newsReleaseName: '',
                bidnames: '',
                modes:'',
                activeNames: '1',
                activeName: '',
                title: '',

                detailsStartTime: '',
                detailsEndTime: '',
                detailsCreatTime: '',
                biddingCountdown: '',

                //////////////////////////////////竞买//////////////////////////////////////////////////////////竞买
                //查询当前商品信息所需信息
                selectData: {
                    "ptargetid": this.$route.query.ptargetid,
                },
                //交易属性table竞价商品基本信息:
                transactionNoticeTemps:[],
                //产品信息table数据
                productInformations: [],
                //产品信息数据模板
                productInformationTemp: {
                    'pproductname': '',
                    'pproducttype': '',
                    'quantity': '',
                    'companyname': '',
                    'description': '',
                },
                LastOffer: [],
                transactionNotices: {
                    pproductname: '',
                    delivery: '',
                    delivertype: '',
                    minquantity: '',
                    payment: '',
                    price: '',
                },
                transactionAttributes: [
                    {
                        data01: '--', deposit: '', depositendtime: '', deliverydetail: '', delivery: '',origin:'',origindetail:'',
                        trtime: '', data07: '--', data08: '--',
                    }
                ],
                //交易属性数据模板
                transactionAttributesTemp: {
                    'deposit': '',
                    'deposittime': '',
                    'delivery': '',
                    'minquantity': '',
                    'minmarkdown': '',
                    'price': '',
                },
                //产品详情
                pdescription: "",
                biddingCountdowns:'',
                biddingRules: [
                    {
                        data01: '公开竞价', begintime: '', endtime: '', data04: '否',
                        data05: '--',
                        data06: '--', data07: '按自报价',
                    }
                ],
                offerFlag: true,
                //报价规则模板
                biddingRulesTemp: {
                    'bidtype': '',
                    'begintime': '',
                    'endtime': '',
                },
            }
        },
        activated(){
            this.getProcureList();
            this.bidnames=this.$route.query.bidname;
            this.title=this.$route.query.title;
            this.selectData.ptargetid = this.$route.query.ptargetid;
        },
        // mounted() {
        //     // this.activeName= '';
        //     this.getProcureList();
        // },
        methods: {
            //时间转换
            getDetailsTime() {
                let start = this.biddingRulesTemp.begintime
                let end = this.biddingRulesTemp.endtime
                this.toNyr(start)
                this.detailsStartTime = this.toNyr(start);
                this.detailsEndTime = this.toNyr(end);
                let newDate = end.split(' ');
                let day = newDate[0].split('/');
                this.detailsCreatTime = day[0] + '年 ' + day[1] + '月 ' + day[2] + '日';
            },
            //启动倒计时
            startCountdown() {
                this.rest = this.getRemaining(this.biddingEndTime);
                if (this.rest > 0) {
                    this.biddingCountdown = this.timeChange(this.rest);
                    setTimeout(this.startCountdown, 1000);
                } else {
                    this.biddingState = true;
                }
            },
            toNyr(date) {
                let newDate = date.split(' ');
                let day = newDate[0];
                let time = newDate[1];
                day = day.split('/');
                time = time.split(':');
                let newDay = day[0] + '年 ' + day[1] + '月 ' + day[2] + '日 ';
                let newTime = time[0] + '时 ' + time[1] + '分 ' + time[2] + '秒';
                return newDay + newTime;
            },
            //获取竞买商品信息
            getProcureList() {
                this.$post('tptargetinfo/detail', this.selectData, true).then((data) => {
                    if (data && data.code === 0) {
                        //数据初始化
                        this.productInformations = [];
                        this.transactionAttributes = [];
                        this.transactionNoticeTemps=[];
                        this.biddingRules = [];
                        this.activeName= '';
                        // this.seller = [];
                        //报价名称赋值
                        this.bidname = data.targetdetail.bidname;
                        //产品信息赋值
                        this.productInformationTemp.pproductname = data.productdetail.pproductname;
                        this.productInformationTemp.pproducttype = data.productdetail.pproducttype;
                        this.productInformationTemp.quantity = data.targetdetail.quantity;
                        this.productInformationTemp.companyname = data.targetdetail.companyname;
                        this.productInformationTemp.description = data.productdetail.description;
                        this.productInformations.push(this.productInformationTemp);
                        //交易属性赋值
                        this.transactionAttributesTemp.deposit = data.targetdetail.deposit;
                        this.transactionAttributesTemp.deposittime = data.targetdetail.deposittime;
                        this.transactionAttributesTemp.delivery = data.targetdetail.delivery;
                        this.transactionAttributesTemp.minquantity = data.targetdetail.minquantity;
                        this.transactionAttributesTemp.minmarkdown = data.targetdetail.minmarkdown;
                        this.transactionAttributesTemp.price = data.targetdetail.price;
                        this.transactionAttributes.push(this.transactionAttributesTemp);
                        // //报价规则赋值
                        this.biddingRulesTemp.bidtype = data.targetdetail.bidtype;
                        this.biddingRulesTemp.begintime = data.targetdetail.begintime;
                        this.biddingRulesTemp.endtime = data.targetdetail.endtime;
                        this.biddingRules.push(this.biddingRulesTemp);
                        //采购单位赋值
                        // this.sellerTemp.companyname = data.targetdetail.companyname;
                        // this.sellerTemp.tel = data.targetdetail.tel;
                        // this.seller.push(this.sellerTemp);
                        //公告竞价商品基本信息
                        this.transactionNotices.pproductname=data.productdetail.pproductname;
                        this.transactionNotices.delivery=data.targetdetail.delivery;
                        this.transactionNotices.delivertype=data.targetdetail.delivertype;
                        this.transactionNotices.minquantity=data.targetdetail.minquantity;
                        this.transactionNotices.payment=data.targetdetail.payment;
                        this.transactionNotices.quantity=data.targetdetail.quantity;
                        this.transactionNotices.price=data.targetdetail.price;
                        this.transactionNoticeTemps.push(this.transactionNotices);
                        this.transactionAttributes[0].trtime = "从" + data.targetdetail.trbegintime + "至" + data.targetdetail.trendtime;
                        this.activeName = data.targetdetail.outbidstate;
                        this.pdescription = data.productdetail.description;

                        //备注说明赋值
                        // this.description = data.targetdetail.description;

                        this.getDetailsTime();

                        this.getLastOffer();
                    } else {
                        this.$message({
                            message: data.msg,
                            type: 'error',
                            offset: 130,
                            showClose: 'true'
                        });
                    }
                })
            },
            getLastOffer() {
                const param = {
                    ptargetid: this.$route.query.ptargetid,
                };
                this.$post('ppcoutbidresultinfo/selectOutbidresultinfoList', param).then((data) => {
                    if (data && data.code === 0) {
                        this.LastOffer = data.data;
                        //this.getBidCount();
                    } else {
                        this.$message({
                            offset: 130,
                            message: data.msg,
                            type: 'error',
                            showClose: 'true'
                        })
                    }
                })
            },
          //导出word
            exportWordNotice: function () {
                let _this = this;
                let wordForm = {
                    bidname: _this.bidname,
                    pproductname: _this.productInformationTemp.pproductname,
                    companyname: _this.productInformationTemp.companyname,
                    detailsStartTime: _this.detailsStartTime,
                    detailsEndTime: _this.detailsEndTime,
                    productname: _this.productInformationTemp.pproductname,
                    quantity: _this.productInformations[0].quantity,
                    price: _this.productInformations[0].price,
                    description1: _this.pdescription,
                    description2: _this.description,
                    detailsCreatTime: _this.detailsCreatTime,
                    data01:_this.biddingRules[0].data01,
                    trtime: _this.transactionAttributes[0].trtime,
                }
                let table1 = _this.setIndex(_this.transactionNoticeTemps);
                let table2 = _this.setIndex(_this.productInformations);
                let table3 = _this.setIndex(_this.LastOffer);
                // 读取并获得模板文件的二进制内容
                JSZipUtils.getBinaryContent("testNoice1.docx", function (error, content) {
                    // input.docx是模板。我们在导出的时候，会根据此模板来导出对应的数据
                    // 抛出异常
                    if (error) {
                        throw error;
                    }
                    // 创建一个JSZip实例，内容为模板的内容
                    let zip = new JSZip(content);
                    // 创建并加载docxtemplater实例对象
                    let doc = new docxtemplater().loadZip(zip);
                    // 设置模板变量的值
                    doc.setData({
                        ...wordForm,
                        table1: table1,
                        table2: table2,
                        table3: table3,
                    });

                    try {
                        // 用模板变量的值替换所有模板变量
                        doc.render();
                    } catch (error) {
                        // 抛出异常
                        let e = {
                            message: error.message,
                            name: error.name,
                            stack: error.stack,
                            properties: error.properties
                        };
                        console.log(JSON.stringify({error: e}));
                        throw error;
                    }

                    // 生成一个代表docxtemplater对象的zip文件（不是一个真实的文件，而是在内存中的表示）
                    let out = doc.getZip().generate({
                        type: "blob",
                        mimeType:
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    });
                    // 将目标文件对象保存为目标类型的文件，并命名
                    saveAs(out, "承诺函（"+_this.bidname+"）.docx");
                });
            },
            setIndex(arr){
                for(let i = 0;i<arr.length;i++){
                    this.$set(arr[i],'index',i+1)
                }
                return arr;
            },
        }
    }
</script>

<style scoped lang="scss">
    @import "../assets/css/index.css";
    @import "../assets/css/page/zxXiangQing.scss";
    /* 更改element-UI按钮样式 */
    .el-button--text {
        color: #b0b0b0;
    }

    .el-button--text:hover {
        color: #b0b0b0;
        font-weight: bold;
    }

    .el-button--text:focus {
        color: #000000;
        font-weight: bold;
    }
</style>
